<template lang="pug">
	component(:is="componentByType", :contents="contents")
</template>

<script>
import { components, props } from '@/mixins/components'

export default {
	name: 'section-galeria',
	props,
	components,
	computed: {
		type () {
			return this.contents.galleryType
		},
		componentByType () {
			switch (this.type) {
				case 'featured': return this.$options.components.GaleriaDestaques
				case 'grid4':
				case 'grid8': return this.$options.components.GaleriaGrid
				default:
					return this.$options.components.GaleriaNav
			}
		}
	}
}
</script>

<style lang="stylus" scoped src="./Galeria.styl"></style>
